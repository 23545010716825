import React from "react";
import { RevealOnMount } from "src/components/reveal-on-mount";
import { SEO } from "src/components/SEO";
import { SecondaryPage } from "src/components/secondary-page";

export interface PageProps {
  pageContext: {
    main: {
      title: string;
      slug: {
        current: string;
      };
      blockContent: [];
    };
    meta: {};
  };
  location: any;
  path: string;
}

const Page = ({ location, path, pageContext }: PageProps) => {
  const { main, meta } = pageContext;
  const { title, slug, blockContent } = main;

  return (
    <RevealOnMount location={location}>
      <SEO metaInfo={meta} pagePath={path} slug={slug.current} />
      <SecondaryPage title={title} blockContent={blockContent} />
    </RevealOnMount>
  );
};

export default Page;
