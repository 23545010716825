import React, { useState } from "react";
import cx from "classnames";

import BlockContent from "@sanity/block-content-to-react";
import { Serializer } from "src/utils/serializer";

import * as styles from "./secondary-page.module.css";

interface SecondaryPageProps {
  title: string;
  blockContent: [];
}

export const SecondaryPage = ({ title, blockContent }: SecondaryPageProps) => {
  return (
    <div className={styles.pageContent}>
      <div className={styles.inner}>
        <h1 className={cx("super", styles.pageTitle)}>{title}</h1>
        <div className="block-content">
          <BlockContent blocks={blockContent} serializers={Serializer} />
        </div>
      </div>
    </div>
  );
};
